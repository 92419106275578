import React from "react"
import { graphql } from "gatsby"
import Image from "/content/assets/SeattleSlewPlate-md.png"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const Plate = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Seattle Slew License Plate</title>
    </Helmet>
    <section class="hero is-halfheight hero-banner" id="plate-hero">
          <div class="hero-body">
          </div>
    </section>      
    <main>
        <h1>‘Horses Work’ Seattle Slew Specialty License Plate</h1>
        <div class="SeattleSlew">
          <img src={Image} alt="plate" title="plate" />
        </div>

        <h2>About the Plate</h2>
<p>The KEEP Foundation is now offering a Kentucky special license plate featuring a photo of Kentucky Bred and Triple Crown winner <strong>Seattle Slew</strong>&#174;  <em>(used with permission of owners Karen and Mickey Taylor and the partners of the</em> <strong>Tony Leonard Collection</strong> who graciously donated this great photo from the collection for our plate).</p>

<h2>How do I get my Seattle Slew Plate?</h2>
<p>To get the license plate, visit your local DMV and bring your current plate with you to exchange for the new Seattle Slew plate.</p>

<h2>Support Kentucky’s Horse Industry</h2>
<p>The first $10 of the cost of each plate and annual renewal will go directly to the educational activities of the KEEP Foundation and to support the KEEP Foundation’s work to address the labor shortages in Kentucky’s horse industry.        </p>

    </main>
    </Layout>
  )
}

export default Plate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`